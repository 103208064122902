import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCouponValue } from "../../store/actions/couponAction";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { useNavigate } from "react-router";
import axios from "axios";

import eGiftCard from "../../assets/e-card-logo.webp";
import tflSmallLogo from "../../assets/sponsoredByTFL.webp.webp";
import { Row, Col, Card, Button, Divider } from "antd";
import redBg from "../../assets/BG/RED.webp";
import orangeBg from "../../assets/BG/ORANGE.webp";
import yellowBg from "../../assets/BG/YELLOW.webp";
import greenBg from "../../assets/BG/GREEN.webp";
import dBlueBg from "../../assets/BG/DARK_BLUE.webp";
import sBlueBg from "../../assets/BG/SKY_BLUE.webp";
import purpleBg from "../../assets/BG/PURPLE.webp";
import dPurpleBg from "../../assets/BG/DARK _PURPLE.webp";
import blackBg from "../../assets/BG/BLACK.webp";

function ApplyCouponModal(props) {
  const [couponData, setCouponData] = useState([]);
  const [visibleCardId, setVisibleCardId] = useState(null);
  const navigate = useNavigate("");

  const user = useSelector((state) => state.userReducer);
  const currentMonth = new Date()
    .toLocaleString("default", { month: "long" })
    .toUpperCase();

  const [show, setShow] = useState(true);
  const [couError, setCouError] = useState("");
  const [loading, setLoading] = useState(false);

  const couponReducer = useSelector((state) => state.couponReducer);
  const couponRef = useRef();
  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    props.onCloseApplycouponModal();
  };

  useEffect(() => {
    const { couponValue, isFetched } = couponReducer;

    if (isFetched) {
      if (couponValue) {
        props.updateCouponValue(couponValue);
        props.onCloseApplycouponModal();
      } else {
        console.log("couponValue", couponValue);
        setCouError("Koden er ugyldig eller utløpt");
      }
    }
  }, [couponReducer, props]);

  const onClickCoupon = async (couponCode) => {
    console.log("Coupon Code Clicked:", couponCode);

    setCouError((prev) => ({ ...prev, [couponCode]: "" })); // Clear error for this specific coupon

    if (!couponCode) {
      setCouError((prev) => ({ ...prev, [couponCode]: "Vennligst skriv inn en gavekortkode" }));
      return;
    }

    setLoading(true);

    try {
      const response = await dispatch(
        getCouponValue({
          coupanCode: couponCode,
          branchId: props.branchId,
          fromWhere: props.type
        })
      );

      console.log("Coupon Applied Successfully:", couponCode);
      localStorage.setItem("appliedCouponCode", couponCode);
      props.setAppliedCoupon(couponCode);
    } catch (error) {
      console.error("Error occurred:", error);
      setCouError((prev) => ({ ...prev, [couponCode]: "Noe gikk galt. Prøv igjen senere." }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedCoupon = localStorage.getItem("appliedCouponCode");
    if (storedCoupon) {
      console.log("Previously applied coupon found:", storedCoupon);
    }
  }, []);
  const backgroundImages = [
    redBg, // For amountPaid <= 1000
    orangeBg, // For amountPaid <= 2000
    yellowBg, // For amountPaid <= 4000
    greenBg, // For amountPaid <= 8000
    dBlueBg, // For amountPaid <= 16000
    sBlueBg, // For amountPaid <= 32000
    purpleBg, // For amountPaid <= 64000
    dPurpleBg, // For amountPaid <= 128000
    blackBg // For amountPaid <= 256000
  ];

  const thresholds = [
    10, 1000, 2000, 4000, 8000, 16000, 32000, 64000, 128000, 256000
  ];

  const getBackgroundImage = (amountPaid) => {
    for (let i = thresholds.length - 1; i >= 0; i--) {
      if (amountPaid > thresholds[i]) {
        return backgroundImages[i];
      }
    }
    return backgroundImages[0];
  };

  const getCoupons = async () => {
    const cardHistoryData = {
      customerPhoneNo: user.phone,
      delStatus: "all"
    };

    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}appuser/getusercoupons`,
        cardHistoryData
      );

      // Filter out coupons generated by "ReserveTable"
      const filteredCoupons = response.data.data.coupons.filter(
        (coupon) => coupon.generatedBy !== "ReserveTable"
      );

      setCouponData(filteredCoupons);
    } catch (error) {
      console.error("Error fetching coupons:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCoupons();
  }, []);
  const couponCard = () => {
    return (
      <>
        {couponData.map((data, index) => {
          const backgroundImage = getBackgroundImage(data.amountPaid || 0);
          const isExpired =
            data.isExpiredCheck == true ||
            (data?.expirayDate && new Date(data.expirayDate) < new Date());
          return (
            <Card
              className={`giftCardBox width-100 mb-4 ${isExpired ? "disabledCard" : ""
                }`}
              key={data._id}
              style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "20px",
                opacity: isExpired ? 0.5 : 1,
                pointerEvents: "auto",
                border: "none",
                boxShadow: "none"
              }}
            >
              <Row className="justify-content-between mb-1 text-white">
                <Col className="d-flex align-items-center">
                  <img alt="eGiftCard" src={eGiftCard} width={"80px"} />
                </Col>
                <Col className="text-right">
                  <h3 className="mb-0 font-16 font-700 text-white">
                    NOK {`${data?.coupanValue.toFixed(2)}`}
                  </h3>
                  <p className="mb-0 font-14">
                    BALANCE {`${data?.reamainigValue.toFixed(2)}`}
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-between mb-2">
                {data?.remAmtForThisMonth <= 0 ? (
                  <span className="text-white font-12">
                    *Saldoen din er fullt brukt denne måneden. Følg med – nye
                    fordeler kommer neste måned!
                  </span>
                ) : (
                  <>
                    {" "}
                    <Col>
                      <h3 className="font-22 text-white mb-0" ref={couponRef}>
                        {data?.coupanCode}
                      </h3>
                    </Col>
                    <Col><Button
                      onClick={() => onClickCoupon(data?.coupanCode)}
                      autoFocus
                      className="greenAccentBg border-radius-50 font-700"
                    >
                      Bruk kode
                    </Button>
                      {couError[data?.coupanCode] && <p className="text-danger">{couError[data?.coupanCode]}</p>}
                    </Col>
                  </>
                )}
              </Row>
              <Row className="justify-content-between">
                <Col className="d-flex justify-content-center align-items-center text-white">
                  MÅNED - {currentMonth}
                </Col>
                <Col>
                  <p className="mb-0 greenAccentText text-right">GJELDER TIL</p>
                  <p className="mb-0 text-white text-right">
                    {data?.expirayDate
                      ? new Date(data.expirayDate).toISOString().split("T")[0]
                      : "N/A"}
                  </p>
                </Col>
              </Row>
              <Divider />
              <Row className="justify-content-between my-2">
                <Col span={4}>
                  <p className="mb-0 greenAccentText">BEGRENSE</p>
                  <p className="mb-0 text-white">
                    {data?.cardAmtForThisMonth.toFixed(2)}
                  </p>
                </Col>
                <Col span={4}>
                  <p className="mb-0 greenAccentText">BRUKT</p>
                  <p className="mb-0 text-white">
                    {Math.abs(
                      (Number(data?.cardAmtForThisMonth) || 0) -
                      (Number(data?.remAmtForThisMonth) || 0)
                    ).toFixed(2)}
                  </p>
                </Col>
                <Col span={4}>
                  <p className="mb-0 greenAccentText">BALANSERE</p>
                  <p className="mb-0 text-white">
                    {data?.remAmtForThisMonth.toFixed(2)}
                  </p>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Divider />
              <Row className="justify-content-between position-relative mt-3">
                <Col className="d-flex justify-content-center align-items-end">
                  <p className="text-white mb-0">
                    {(() => {
                      if (data.generatedBy === "admin") {
                        return "BEGAVET AV ADMIN";
                      } else if (data.generatedBy === "CoinConversions") {
                        return "MYNTER KONVERTER";
                      } else {
                        return data?.recipientName?.toUpperCase();
                      }
                    })()}
                  </p>
                </Col>
                <Col
                  className="position-absolute"
                  style={{ bottom: "0", right: "0" }}
                >
                  <img
                    src={tflSmallLogo}
                    alt="sponsored by tfl"
                    width={"50px"}
                  />
                </Col>
              </Row>
            </Card>
          );
        })}
      </>
    );
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "black" }}>Bruk Agentkort</Modal.Title>
      </Modal.Header>
      <Row className="p-3" style={{ height: "70vh", overflowY: "scroll" }}>
        {!loading ? (
          <Col span={24}>
            {couponData.length === 0 ? (
              <>
                {!user.phone ?
                  <Row gutter={[16, 16]}>
                    <Col span={24} className="text-center"><p className="text-dark  width-100 font-18 font-700 text-center">
                      Det er ikke noe agentkort som<br /> kan brukes på kontoen din.
                    </p></Col>
                    <Col xs={24} lg={24}><Button className="width-100 font-14 text-white saveBtn" onClick={() => navigate("/signup")}>For å kjøpe et agentkort (registrer deg selv)</Button></Col>
                    <Col xs={24} lg={24}><Button className="width-100 font-14 text-white saveBtn" onClick={handleClose}>Fortsett uansett</Button></Col>
                    {/* "Det er ikke noe agentkort registrert på din konto."
For å kjøpe et agentkort (registrer deg) 
Fortsett her:  */}
                  </Row> :
                  <div className="d-flex flex-column justify-content-centeralign-items-center width-100 height-100">
                    <p className="text-dark font-18 font-700">
                      Det er ikke noe agentkort som<br /> kan brukes på kontoen din.
                    </p>
                    <p>Trinn for å kjøpe et gavekort:</p>
                    <ul className="text-dark">
                      <li>Gå til Agentkort-seksjonen.</li>
                      <li>Utforsk alternativene og velg agentkortet du ønsker.</li>
                      <li>Fullfør betalingsprosessen for å gjennomføre kjøpet.</li>
                      <li>Når transaksjonen er fullført, blir ditt forhåndsbetalte agentkort oppdatert og kan brukes i alle deler av EzyGo-nettverket.</li>
                    </ul>
                    {/* <a
                  className="aboutNavLink greenAccentBg border-radius-10 font-900"
                  style={{ cursor: "pointer", padding: "8px 50px" }}
                  onClick={() => navigate("/tflgiftcard")}
                >
                  Kjøp nå
                </a> */}
                  </div>}
              </>

            ) : (
              couponCard()
            )}
          </Col>
        ) : (
          <Col span={24}>
            <p>Loading...</p> {/* Add a loading message or spinner */}
          </Col>
        )}
      </Row>
    </Modal>
  );
}

export default ApplyCouponModal;
