import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  DatePicker,
  TimePicker,
  Typography,
  Row,
  Col,
  Select,
  message
} from "antd";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  LeftCircleOutlined
} from "@ant-design/icons";
import BottomFooter from "../Footer/BottomFooter";
import { isMobile } from "react-device-detect";
import moment from "moment";
import "./form.css";
import { apiUrl } from "../../helper/endpoint";

const { Option } = Select;

const countryCodes = [
  { code: "NO", dial_code: "+47" },
  { code: "IN", dial_code: "+91" }
];

const GeneralForm = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();

  const serviceTitle = location.state?.serviceTitle;
  console.log("service title in form", location.state);
  const onFinish = async (values) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      countryCode,
      date,
      time,
      queryMessage
    } = values;

    const payload = {
      firstName,
      lastName,
      subject: serviceTitle,
      email,
      countryCode,
      phone,
      date: date.format("DD-MM-YYYY"),
      time: time.format("HH:mm"),
      message: queryMessage,
      platform: isMobile ? "EzyGo IOS" : "EzyGo Web"
    };
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/treeDrive/treedriveemail/digitalServicesForm`,
        payload
      );

      if (response.status === 200) {
        console.log("response of digitalServicesForm", response);
        message.success("Sendt inn!"); // Now this should work
        form.resetFields();
        navigate("/digitalServices");
      } else {
        // Handle non-200 success codes if needed
        console.error("Unexpected status code:", response.status);
        message.error("En uventet feil oppstod. Prøv igjen.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Kunne ikke sende inn skjemaet. Vennligst prøv igjen."); // This should work now
    }
  };

  const [time, setTime] = useState("");
  const content = () => (
    <>
      <Form
        className="height-auto p-1 border-radius-10"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ countryCode: "+47" }}
      >
        {/* {isMobile && ( */}
        <Row className="justify-content-between mb-3">
          <Col className="d-flex align-items-center">
            <h3 className="mb-0 font-22">{serviceTitle}</h3>
          </Col>
          <Col>
            <LeftCircleOutlined
              onClick={() => navigate("/digitalServices")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "40px"
              }}
            />
          </Col>
        </Row>
        {/* )} */}
        <Form.Item
          name="firstName"
          rules={[
            { required: true, message: "Vennligst skriv inn fornavnet ditt." }
          ]}
        >
          <Input placeholder="Fornavn" size="large" />
        </Form.Item>
        <Form.Item name="lastName">
          <Input placeholder="Etternavn" size="large" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Skriv inn en gyldig e-post"
            }
          ]}
        >
          <Input placeholder="E-post" size="large" />
        </Form.Item>
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item
              name="countryCode"
              rules={[
                { required: true, message: "Vennligst velg en landskode." }
              ]}
            >
              <Select size="large">
                {countryCodes.map(({ code, dial_code }) => (
                  <Option key={code} value={dial_code}>
                    {`${code} (${dial_code})`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Vennligst skriv inn telefonnummeret ditt."
                },
                {
                  pattern: /^[0-9]*$/, // ✅ Only numbers allowed
                  message: "Telefonnummeret kan kun inneholde tall."
                },
                {
                  max: 10,
                  message: "Telefonnummeret kan ikke ha mer enn 10 sifre."
                }
              ]}
            >
              <Input
                size="large"
                placeholder="Telefonnummer"
                maxLength={10}
                onChange={(e) => {
                  const onlyNums = e.target.value.replace(/\D/g, ""); // ✅ Remove non-numeric characters
                  form.setFieldsValue({ phone: onlyNums });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Typography.Text>
          Velg den mest praktiske datoen og klokkeslettet for samtalen.
        </Typography.Text>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="date">
              <DatePicker
                style={{ width: "100%" }}
                suffixIcon={<CalendarOutlined />}
                getPopupContainer={(trigger) => trigger.parentElement} // Fix for iOS WebView
                disabledDate={(current) =>
                  current && current < moment().startOf("day")
                }
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* <Form.Item name="time"> */}
            <TimePicker
                style={{ width: "100%" }}
                suffixIcon={<ClockCircleOutlined />}
                size="large"
                placement="top" // Ensures it opens downward
                getPopupContainer={(trigger) => trigger.parentElement} // Ensures it stays inside the parent container
              />
            {/* <Form.Control
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              step="60" // 1-minute interval
            /> */}

            {/* </Form.Item> */}
          </Col>
        </Row>
        <Form.Item name="queryMessage">
          <Input.TextArea placeholder="Beskjed" rows={3} size="large" />
        </Form.Item>
        <Row justify="space-between" className="mt-3">
          <Button
            type="primary"
            htmlType="submit"
            className="submit-button width-100 border-radius-5"
          >
            Send inn
          </Button>
        </Row>
      </Form>
    </>
  );

  return (
    <>
      {isMobile ? (
        <Row className="vh90andScroll p-3">{content()}</Row>
      ) : (
        <div style={{ marginTop: "64px" }} className="mission profilePages">
          <Row className="align-items-center justify-content-center">
            <Col lg={9} className="commonShadowAndRound my-5 bg-white p-5">
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
};

export default GeneralForm;
