import React from "react";
import { Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import BottomFooter from "../Footer/BottomFooter";
import coin5 from "../../assets/mobile/coins/coins5.png";
import coin4 from "../../assets/mobile/coins/coins4.webp";
import coin3 from "../../assets/mobile/coins/coins3.webp";
import coin2 from "../../assets/mobile/coins/coins2.webp";

function Modal1Slide() {
  const { modalId } = useParams();
  const navigate = useNavigate();

  // Data for different modals, including images
  const modalData = {
    1: {
      title: "Aktiver EzyGo AI Agent!",
      description: [
        "Registrer deg på EzyGo-nettverket.",
        "Sett opp din personlige AI-agent for å begynne å tjene automatisk.",
        "Aktiver agenten din og la den fungere for deg uten problemer."
      ],
      image: coin3
    },
    2: {
      title: "La agenten din jobbe for deg",
      description: [
        "Utforsk EzyGo-nettverket med AI-agenten din.",
        "Få tilgang til tjenester og samarbeid med andre EzyGo-agenter.",
        "Tjen TFL Digital Coins for hver handling og samarbeid."
      ],
      image: coin4
    },
    3: {
      title: "Tjen mynter for hver handling",
      description: [
        "Samle TFL-mynter for hver oppgave du fullfører.",
        "Hold deg aktiv for å maksimere inntektene dine.",
        "Spar mynter opp til innløsningsgrensen for å oppfylle dine behov og ønsker."
      ],
      image: coin2
    },
    4: {
      title: "Kjøp eller gi e-kort med forhåndsverdi",
      description: [
        "Kjøp agent-e-kort med forhåndsverdi for ekstra fordeler.",
        "Gaveagent e-kort til andre og nyt delte besparelser.",
        "Bruk klokt og tjen ekstra mynter på hver transaksjon med Agent E-kortet ditt."
      ],
      image: coin5
    },
    5: {
      title: "Hold og løs inn for større belønninger",
      description: [
        "Jo lenger du holder på myntene, desto større blir belønningene.",
        "Løs inn lagrede mynter for eksklusive tilbud og fordeler",
        "Fortsett å samle inn, fortsett å løse inn og nyt livstidsfordeler!    "
      ],
      image: coin5
    }
  };

  const data = modalData[modalId];

  if (!data) {
    return <div>Ugyldig modal ID</div>;
  }

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center vh-100"
        style={{ background: "#d7f1ff" }}
      >
        <div
          className="text-center"
          style={{
            background: "#ffffff",
            borderRadius: "15px",
            width: "90%",
            maxWidth: "400px",
            padding: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)"
          }}
        >
          <img
            src={data.image}
            alt="Modal Graphic"
            style={{ width: "100px" }}
          />
          <h3 style={{ color: "#2d8af7", margin: "20px 0" }}>{data.title}</h3>
          <ul
            style={{
              listStyleType: "none",
              padding: "0",
              margin: "0",
              textAlign: "left"
            }}
          >
            {data.description.map((item, index) => (
              <li
                key={index}
                style={{
                  marginBottom: "10px",
                  fontSize: "14px",
                  color: "#444"
                }}
              >
                <span
                  role="img"
                  aria-label="icon"
                  style={{ marginRight: "10px" }}
                >
                  📌
                </span>
                {item}
              </li>
            ))}
          </ul>
          <Button
            variant="primary"
            onClick={() => navigate("/home")}
            style={{
              marginTop: "20px",
              background: "#2d8af7",
              border: "none",
              padding: "10px 20px"
            }}
          >
            Lukke
          </Button>
        </div>
      </div>
      <BottomFooter />
    </>
  );
}

export default Modal1Slide;
