// import { createSlice, current } from "@reduxjs/toolkit";
// import { revertCart } from "../actions/rootaction";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// import getDiscount from "../../helper/discountHelper";

// let initialState = {
//   cartItems: [],
//   totalPrice: 0,
//   extraPrice: 0,
//   itemsPrice: 0,
//   discountPercent: 0,
//   discountPrice: 0
// };
// let cartSlice = createSlice({
//   name: "cartslice",
//   extraReducers: (builder) => {
//     return builder.addCase(revertCart, () => {
//       storage.removeItem("persist:root");
//       return initialState;
//     });
//   },
//   initialState,

//   reducers: {
//     addToCart: (state, action) => {
//       state.cartItems = [...state.cartItems, action.payload];
//       state.totalPrice =
//         state.totalPrice +
//         Number(action.payload.totalPrice > 0 ? action.payload.totalPrice : 0);
//       state.extraPrice =
//         state.extraPrice +
//         Number(action.payload.extraPrice > 0 ? action.payload.extraPrice : 0);
//       state.itemsPrice =
//         state.itemsPrice +
//         Number(action.payload.price > 0 ? action.payload.price : 0);
//       state.discountPercent = action.payload.discountCalculated
//         ? action.payload.discountCalculated
//         : 0;
//       state.discountPrice = action.payload.discountPrice
//         ? action.payload.discountPrice
//         : 0;
//     },
//     removeProductFromCart: (state, action) => {
//       let products = [...current(state.cartItems)];
//       let item = products[action.payload.index];
//       state.itemsPrice = state.itemsPrice - item.price;
//       let qquantity = item.quantity;
//       let thisItemPrice = item.incPrice;
//       let thisTotalPrice = item.totalPrice;

//       qquantity = qquantity - 1;
//       thisTotalPrice = qquantity * item.incPrice;

//       let updatedItems = {
//         ...item,
//         quantity: qquantity,
//         totalPrice: thisTotalPrice
//       };

//       products[action.payload.index] = updatedItems;
//       state.cartItems = products;

//       state.itemsPrice = state.itemsPrice - item.price;
//       state.totalPrice = state.totalPrice - thisItemPrice;

//       if (qquantity < 1) {
//         state.cartItems.splice(action.payload.index, 1);
//       }

//       let { discountCalculated, discountPrice } = getDiscount(
//         state.totalPrice,
//         action.payload.restaurant,
//         action.payload.orderType
//       );

//       state.discountPrice = discountPrice;
//       state.discountPercent = discountCalculated;
//     },
//     addProductToCart: (state, action) => {
//       let products = [...current(state.cartItems)];
//       let index = products.findIndex(
//         (p) =>
//           p._id === action.payload.id &&
//           JSON.stringify(p.instruction) === JSON.stringify(action.payload.instruction) &&
//           JSON.stringify(p.extras) === JSON.stringify(action.payload.extras)
//       );

//       let incPrice = Number(action.payload.incPrice) || 0;
//       let price = Number(action.payload.price) || 0;

//       if (index !== -1) {
//         // If product exists, increase quantity
//         products[index].quantity += 1;
//         products[index].totalPrice += incPrice;
//       } else {
//         // Add new product if it doesn't exist
//         products.push({
//           ...action.payload,
//           quantity: 1,
//           totalPrice: incPrice
//         });
//       }

//       state.cartItems = products;
//       state.totalPrice = (Number(state.totalPrice) || 0) + incPrice;
//       state.itemsPrice = (Number(state.itemsPrice) || 0) + price;

//       let { discountCalculated, discountPrice } = getDiscount(
//         state.totalPrice,
//         action.payload.restaurant,
//         action.payload.orderType
//       );

//       state.discountPrice = discountPrice;
//       state.discountPercent = discountCalculated;
//     },

//     removeExtraItemFromCart: (state, action) => {
//       let products = [...current(state.cartItems)];
//       let item = products[action.payload.productIndex];
//       let extraItems = item.extraItems ? [...item.extraItems] : [];
//       let extraProducts = extraItems[action.payload.extraIndex];
//       state.totalPrice = state.totalPrice - extraProducts.incPrice;
//       state.extraPrice = state.extraPrice - extraProducts.incPrice;
//       let oldExtraItems = [...item.extraItems];

//       oldExtraItems.splice(action.payload.extraIndex, 1);
//       let updatedItem = { ...item, extraItems: oldExtraItems };

//       products[action.payload.productIndex] = updatedItem;

//       state.cartItems = products;
//     },

//     emptyCart: (state, action) => {
//       state.cartItems = [];
//       state.totalPrice = 0;
//       state.extraPrice = 0;
//       state.itemsPrice = 0;
//       state.discountPercent = 0;
//       state.discountPrice = 0;
//     }
//   }
// });

// export default cartSlice.reducer;
// export const cartAction = cartSlice.actions;

import { createSlice, current } from "@reduxjs/toolkit";
import { revertCart } from "../actions/rootaction";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import getDiscount from "../../helper/discountHelper";

let initialState = {
  cartItems: [],
  totalPrice: 0,
  extraPrice: 0,
  itemsPrice: 0,
  discountPercent: 0,
  discountPrice: 0
};

let cartSlice = createSlice({
  name: "cartslice",
  extraReducers: (builder) => {
    return builder.addCase(revertCart, () => {
      storage.removeItem("persist:root");
      return initialState;
    });
  },
  initialState,
  reducers: {
    addToCart: (state, action) => {
      state.cartItems = [...state.cartItems, action.payload];
      state.totalPrice += Number(
        action.payload.totalPrice > 0 ? action.payload.totalPrice : 0
      );
      state.extraPrice += Number(
        action.payload.extraPrice > 0 ? action.payload.extraPrice : 0
      );
      state.itemsPrice += Number(
        action.payload.price > 0 ? action.payload.price : 0
      );
      state.discountPercent = action.payload.discountCalculated || 0;
      state.discountPrice = action.payload.discountPrice || 0;
    },
    removeProductFromCart: (state, action) => {
      let products = [...current(state.cartItems)];
      let index = action.payload.index;
      let item = products[index];

      if (!item) return;

      let qquantity = item.quantity;
      let thisItemPrice = item.incPrice;
      let thisTotalPrice = item.totalPrice;

      qquantity -= 1;
      thisTotalPrice = qquantity * item.incPrice;

      if (qquantity > 0) {
        products[index] = {
          ...item,
          quantity: qquantity,
          totalPrice: thisTotalPrice
        };
      } else {
        products.splice(index, 1);
      }

      state.cartItems = products;
      state.itemsPrice -= item.price;
      state.totalPrice -= thisItemPrice;

      let { discountCalculated, discountPrice } = getDiscount(
        state.totalPrice,
        action.payload.restaurant,
        action.payload.orderType
      );

      state.discountPrice = discountPrice;
      state.discountPercent = discountCalculated;
    },
    addProductToCart: (state, action) => {
      let products = [...current(state.cartItems)];
      let index = products.findIndex(
        (p, i) =>
          p._id === action.payload.id &&
          JSON.stringify(p.instruction) ===
            JSON.stringify(action.payload.instruction) &&
          JSON.stringify(p.extras) === JSON.stringify(action.payload.extras) &&
          i === action.payload.index
      );

      let incPrice = Number(action.payload.incPrice) || 0;
      let price = Number(action.payload.price) || 0;

      if (index !== -1) {
        products[index].quantity += 1;
        products[index].totalPrice += incPrice;
      } else {
        products.push({ ...action.payload, quantity: 1, totalPrice: incPrice });
      }

      state.cartItems = products;
      state.totalPrice = (Number(state.totalPrice) || 0) + incPrice;
      state.itemsPrice = (Number(state.itemsPrice) || 0) + price;

      let { discountCalculated, discountPrice } = getDiscount(
        state.totalPrice,
        action.payload.restaurant,
        action.payload.orderType
      );

      state.discountPrice = discountPrice;
      state.discountPercent = discountCalculated;
    },
    removeExtraItemFromCart: (state, action) => {
      let products = [...current(state.cartItems)];
      let item = products[action.payload.productIndex];
      if (!item || !item.extraItems) return;

      let extraItems = [...item.extraItems];
      let extraProduct = extraItems[action.payload.extraIndex];
      if (!extraProduct) return;

      state.totalPrice -= extraProduct.incPrice;
      state.extraPrice -= extraProduct.incPrice;

      extraItems.splice(action.payload.extraIndex, 1);
      products[action.payload.productIndex] = { ...item, extraItems };

      state.cartItems = products;
    },
    emptyCart: (state) => {
      state.cartItems = [];
      state.totalPrice = 0;
      state.extraPrice = 0;
      state.itemsPrice = 0;
      state.discountPercent = 0;
      state.discountPrice = 0;
    }
  }
});

export default cartSlice.reducer;
export const cartAction = cartSlice.actions;
